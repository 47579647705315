import "./Inicio.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { BACKEND_API } from "../Rutabackend"; // Asegúrate de tener correctamente definido BACKEND_API

const Inicio = () => {
  const [totalPacientes, setTotalPacientes] = useState(0);
  const [consultasDiarias, setConsultasDiarias] = useState(0);
  const [consultasMensuales, setConsultasMensuales] = useState(0);

  const obtenerTotalPacientes = async () => {
    try {
      const response = await axios.get(`${BACKEND_API}api/pacientes/contar`);
      setTotalPacientes(response.data.total);
    } catch (error) {
      console.error("Error al obtener el total de pacientes:", error);
    }
  };

  useEffect(() => {
    obtenerTotalPacientes();
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); 
    const day = String(today.getDate()).padStart(2, "0"); 
    const current_date = `${year}-${month}-${day}`;
    const ObtenerConsultasDiarias = async () => {
      try {
        const fechaActual = new Date().toISOString().slice(0, 10); // Formato YYYY-MM-DD
        const response = await axios.get(
          `${BACKEND_API}api/todaslasconsultaobtener`
        );
        const registers = response.data;
        const registers_today = registers.filter((item) => {
          const itemDate = new Date(item.fechade_consulta)
            .toISOString()
            .split("T")[0];
          return itemDate === current_date;
        });
        setConsultasDiarias(registers_today.length);
      } catch (error) {
        console.error("Error al obtener el total de consultas diarias:", error);
      }
    };
    ObtenerConsultasDiarias();
  }, []);

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Mes actual con dos dígitos
    const day = String(today.getDate()).padStart(2, "0"); // Día actual con dos dígitos
    const current_date = `${year}-${month}-${day}`;
    const current_month = `${year}-${month}`; // Mes actual (YYYY-MM)

    const ObtenerConsultasDiariasYMensuales = async () => {
      try {
        // Realiza la solicitud a tu API para obtener todas las consultas
        const response = await axios.get(`${BACKEND_API}api/todaslasconsultaobtener`);
        console.log(response);
        const registers = response.data;

        // Filtra las consultas diarias
        const registers_today = registers.filter((item) => {
          const itemDate = new Date(item.fechade_consulta).toISOString().split("T")[0];
          return itemDate === current_date;
        });
        setConsultasDiarias(registers_today.length);

        // Filtra las consultas del mes actual
        const registers_this_month = registers.filter((item) => {
          const itemMonth = new Date(item.fechade_consulta).toISOString().slice(0, 7); // YYYY-MM
          return itemMonth === current_month;
        });
        setConsultasMensuales(registers_this_month.length);
        
      } catch (error) {
        console.error("Error al obtener las consultas:", error);
      }
    };

    ObtenerConsultasDiariasYMensuales();
  }, []);


  return (
    <div className="principal">
      <h2>Bienvenida a la Clínica Médica</h2>
      <div className="container">
        <section className="section">
          <h3>Pacientes de la Clínica</h3>
          <br />
          <p>{totalPacientes}</p> {/* Mostrar el total de pacientes */}
        </section>
        <section className="section">
          <h3>Consultas diarias</h3>
          <br />
          <p>{consultasDiarias}</p>{" "}
          {/* Mostrar el total de consultas diarias */}
        </section>
        <section className="section">
          <h3>Consultas mensuales</h3>
          <br />
          <p>{consultasMensuales}</p>{" "}
          {/* Mostrar el total de consultas mensuales */}
        </section>
      </div>
    </div>
  );
};

export default Inicio;
